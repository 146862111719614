/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import HeadHTMLBlock from '../../ContentBlocks/HeadHTMLBlock';
import SEOMetaTag from '../../ContentBlocks/SEOMetaTag';
import { getCartridgeType } from '@/utils/getCartridgeType';

export type HeadTemplateProps = {
  content: Array<any>;
  isPreview?: boolean;
};

export const HeadTemplate = ({ content, isPreview }: HeadTemplateProps) => {
  // @ts-expect-error refine type
  const renderContentBlocks = (contentType, contentItem, i) => {
    const key = `${contentType}-${i}`;

    if (typeof window !== 'undefined' && isPreview) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const ref = React.useRef();
      setTimeout(() => {
        window.xmgrPreview.addContentItemId(ref.current, contentItem);
      }, 2000);
    }

    switch (contentType) {
      case 'HTMLBlock':
        return <HeadHTMLBlock contentItem={contentItem} key={key} />;

      case 'SEOMetaTag':
        return <SEOMetaTag contentItem={contentItem} key={key} />;
    }
  };

  const contentExists = content != null;

  return (
    <>
      {contentExists &&
        content.map((contentItem, i) => {
          const contentType = getCartridgeType(contentItem['@type']);

          if (contentType === 'Slot' && contentItem.contents.length > 0) {
            return contentItem.contents.map(
              // @ts-expect-error refine type
              (singleContentItem, mappedContentIndex) =>
                renderContentBlocks(
                  getCartridgeType(singleContentItem['@type']),
                  singleContentItem,
                  mappedContentIndex
                )
            );
          }

          return renderContentBlocks(contentType, contentItem, i);
        })}
    </>
  );
};

export default HeadTemplate;
