/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { PageSpecificAnalyticsDataType } from '@/types/analytics';

/**
 * The set difference of A and B is the set B without all the elements of A
 * @param A a `PageSpecificAnalyticsData` object consisting of key-value pairs
 * @param B a set of keys as an array of strings. Defaults to `searchAnalyticsKeys`
 * @returns the relative complement as an array of strings
 */
export function getAnalyticsRelativeComplementOf(
  A: PageSpecificAnalyticsDataType,
  B: string[] | undefined = searchAnalyticsKeys
) {
  return B.filter((key) => !Object.keys(A).includes(key));
}

const searchAnalyticsKeys = [
  'prodView',
  'productBrandList',
  'productCorePriceList',
  'productDealMessage',
  'productFilterSelection',
  'productFindingMethod',
  'productFitmentBanner',
  'productFitmentCheckMessage',
  'productGuide',
  'productIdList',
  'productNotAvailableList',
  'productPart',
  'productPartList',
  'productPartNumberList',
  'productPrice',
  'productRecommendationTypeList',
  'productRepositoryIdList',
  'productStrikeThroughPricing',
  'productToastNotification',
];
