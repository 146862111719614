/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { dehydrate } from '@tanstack/react-query';
import { HomePage } from '@/features/home/pages/HomePage';
import { prefetchHomePageContent } from '@/features/home/api/getHomePageContent';
import { prefetchHomePageV2Content } from '@/features/home/api/getHomePageContentV2';
import { PageContextType } from '@/next-types';
import { getHeaderDataFromCache } from '@/features/header/api/getHeader';
import { configureSharedPageData } from '@/utils/configureSharedPageData.server';
import { prefetchPreFooterData } from '@/features/prefooter';
import { getPathnameFromAsPath } from '@/utils/urlHelpers';
import { getDeviceType } from '@/utils/getDeviceType.server';
import { getKiboDecisionFlag } from '@/features/kibo';
import { countryCodes } from '@/constants/locale';

export const config = {
  unstable_JsPreload: false,
};

function Page() {
  return <HomePage />;
}

Page.getInitialProps = async (context: PageContextType) => {
  const { req } = context;

  if (__IS_SERVER__) {
    const { axios, queryClient, initialAppState } = await configureSharedPageData(context);

    const headerData = getHeaderDataFromCache(queryClient);
    const catalogVehicleId = headerData?.vehicleMap?.catalogVehicleId || '0';
    const contentStackHomePageEnabled =
      initialAppState.locale === countryCodes.ptBr ||
      getKiboDecisionFlag(queryClient, 'cmsHomeEnabled');

    const deviceType = getDeviceType(req);

    const dataToFetch = [
      !contentStackHomePageEnabled &&
        prefetchHomePageContent(queryClient, initialAppState?.locale!, axios),
      contentStackHomePageEnabled &&
        prefetchHomePageV2Content(queryClient, context, headerData?.segments?.sort() ?? []),
    ];

    if (deviceType !== 'bot' && initialAppState.locale === countryCodes.enUS) {
      dataToFetch.push(
        prefetchPreFooterData(
          queryClient,
          {
            locale: initialAppState.locale,
            seourl: getPathnameFromAsPath(context.asPath).replace(/(\/bn\/|\/b\/).*/, ''),
            vehicleId: catalogVehicleId,
            isCMSEnabled: false,
          },
          axios
        )
      );
    }

    await Promise.allSettled(dataToFetch);

    return {
      initialAppState,
      dehydratedState: dehydrate(queryClient),
    };
  }

  return {};
};

export default Page;
