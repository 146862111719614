/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
// TODO: Will be removed once BE changes are completed
// eslint-disable-next-line no-restricted-imports
import Parser from 'html-react-parser';
import Head from 'next/head';

type Props = {
  contentItem: {
    content: string;
  };
};

export const HeadHTMLBlock = ({ contentItem }: Props) => {
  const htmlTag = contentItem.content;
  const parsedHtml = Parser(htmlTag ?? '') as JSX.Element;
  return (
    <Head>
      {htmlTag?.startsWith('<title>') ? <title>{parsedHtml?.props?.children}</title> : parsedHtml}
    </Head>
  );
};

export default HeadHTMLBlock;
