/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { PreFooterLink } from './PreFooterLink';
import styles from './styles.module.scss';
import { Button } from '@/components/Button';
import NextImage from '@/components/NextImage';
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import usePrevious from '@/utils/usePrevious';
import { useRouter } from 'next/router';
import { eventConstants } from '@/constants/event';
import type { ContentStackLink, ContentStackPrefooter } from '../../interface/content-types';

const chevron = '/images/footer/footer_down_arrow.svg';

type Props = {
  column: Exclude<ContentStackPrefooter['column'], undefined>[0];
};

export function MobileLinkColumn({ column }: Props) {
  const { column_title = '', link = [] } = column;

  const router = useRouter();
  const [chevronUp, setChevronUp] = useState(false);
  const contentLength = link.length;

  // Show all by default so that bots and non-JS users can see the content
  const [showingAll, setShowingAll] = useState(true);
  const [isAutoFocus, setIsAutoFocus] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(contentLength);
  const previousItemToShow = usePrevious(itemsToShow);

  const handleShowHide = (): void => {
    setShowingAll(!showingAll);
    setIsAutoFocus(!isAutoFocus);
  };

  useEffect(() => {
    // Hide unnecessary links for JS users
    setShowingAll(false);
  }, []);

  useEffect(() => {
    const lesser = showingAll
      ? contentLength < 10
        ? contentLength
        : 10
      : contentLength < 5
      ? contentLength
      : 5;
    setItemsToShow(lesser);
  }, [contentLength, showingAll]);

  const handleOnClick = () => setChevronUp(!chevronUp);

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === eventConstants.enterKeyType) {
      setChevronUp(!chevronUp);
    }
  };

  const handleOnAutoFocusedKeyPress = (
    e: React.KeyboardEvent<HTMLDivElement>,
    content: ContentStackLink
  ) => {
    const { href } = content;
    if (e.key === eventConstants.enterKeyType) {
      void router.push(href);
    }
  };
  const showLess = useLabel('label_Show_Less');
  const showLessAriaLabel = `${showLess} ${column_title}`;
  const showAll = useLabel('label_Show_All');
  const showAllAriaLabel = `${showAll} ${column_title}`;
  const lblExpansiblePanel = useLabel('label_ShelfPage_leftSideBar_ExpansiblePanel');

  return (
    <div className={styles.linkColumnContainer}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.linksColumnTitleContainer}
        onKeyPress={handleOnKeyPress}
        onClick={handleOnClick}
        role="presentation"
        tabIndex={-1}
      >
        <h2 className={styles.linkColumnTitle}>{column_title}</h2>
        <NextImage
          tabIndex={0}
          role="button"
          width={24}
          height={12}
          aria-expanded={chevronUp}
          alt={`${column_title} ${lblExpansiblePanel}`}
          src={chevron}
          className={`${styles.chevron} ${chevronUp ? styles.chevronUp : styles.chevronDown}`}
          loading="lazy"
        />
      </div>
      <div
        data-testid={'links_container'}
        className={cx(styles.linksContainer, {
          [styles.showLinks]: chevronUp || showingAll,
          [styles.hideLinks]: !chevronUp,
        })}
      >
        {renderLinkListColumn(link.slice(0, itemsToShow))}
        {contentLength > 5 && (
          <Button
            data-testid="show-button"
            onClick={handleShowHide}
            variant="ghostPureText"
            customClass={styles.allOrLess}
            ariaLabel={showingAll ? showLessAriaLabel : showAllAriaLabel}
            ariaExpanded={showingAll ? true : false}
          >
            <Label label={showingAll ? 'label_Show_Less' : 'label_Show_All'} />
          </Button>
        )}
      </div>
      {!chevronUp && <hr className={styles.horizontalLine} />}
    </div>
  );

  function renderLinkListColumn(listItems: ContentStackLink[]) {
    return (
      <ul>
        {listItems.map((linkItem, index) => (
          <li key={`link_${index}`} className={styles.linkContainer}>
            <PreFooterLink
              csLink={linkItem}
              isLinkToFocus={index === previousItemToShow}
              className={styles.linkItem}
              isAutoFocus={isAutoFocus}
              handleOnAutoFocusedKeyPress={(e) => handleOnAutoFocusedKeyPress(e, linkItem)}
            />
          </li>
        ))}
      </ul>
    );
  }
}
