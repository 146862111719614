/**
 * Copyright 2020-2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useRef, useEffect, useState } from 'react';
import { CMSLink } from '../CMSLink/CMSLink';
import type { ContentStackLink } from '../../interface/content-types';

type Props = {
  csLink: ContentStackLink;
  className?: string;
  isLinkToFocus?: boolean;
  isAutoFocus?: boolean;
  handleOnAutoFocusedKeyPress?: React.KeyboardEventHandler<HTMLDivElement>;
};

export function PreFooterLink({
  csLink,
  className,
  isLinkToFocus,
  isAutoFocus,
  handleOnAutoFocusedKeyPress,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const [shouldFocusTop, setShouldFocusTop] = useState(false);
  useEffect(() => {
    if (shouldFocusTop) {
      const azLogoFixed = document.getElementById('azLogoLinkFixed');
      azLogoFixed?.focus();
      setShouldFocusTop(false);
    }
  }, [shouldFocusTop]);

  useEffect(() => {
    if (isLinkToFocus && isAutoFocus) {
      ref.current?.focus();
    }
  }, [ref, isLinkToFocus, isAutoFocus]);

  return (
    <CMSLink
      to={csLink.href}
      className={className}
      onClick={() => {
        setShouldFocusTop(true);
      }}
      tabIndex={isLinkToFocus ? -1 : 0}
    >
      {isLinkToFocus ? (
        <span tabIndex={0} ref={ref} onKeyPress={handleOnAutoFocusedKeyPress}>
          {csLink.title}
        </span>
      ) : (
        <span tabIndex={-1}>{csLink.title}</span>
      )}
    </CMSLink>
  );
}
