/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { isTypeable } from '@/utils/typeGuards/isTypeable';

export const getContentBlockTypes = (
  sectionContent: Array<{ '@type': string } | unknown> | undefined
): string[] => {
  const blockTypes: string[] = [];
  sectionContent?.forEach((contentObject) => {
    if (isTypeable(contentObject)) {
      const blockType = contentObject['@type']?.split('_')[1];

      if (blockType) {
        blockTypes.push(blockType);
      }
    }
  });
  return blockTypes;
};
