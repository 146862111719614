/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { isNameable } from '@/utils/typeGuards/isNameable';

export const getContentBlockNames = (
  sectionContent:
    | Array<{ name?: string | undefined } | Record<string, string> | unknown>
    | undefined
): Array<string | undefined> => {
  const blockNames: Array<string | undefined> = [];
  sectionContent?.forEach((contentObject) => {
    if (isNameable(contentObject)) {
      blockNames.push(contentObject.name);
    }
  });

  return blockNames;
};
