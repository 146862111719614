/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import Head from 'next/head';

type MetaTagContent = {
  metaTagValue: string;
  metaTagName: string;
};

type Props = {
  contentItem: MetaTagContent;
};

export const SEOMetaTag = ({ contentItem }: Props) => {
  let content = <meta name={contentItem.metaTagName} content={contentItem.metaTagValue} />;
  switch (contentItem.metaTagName) {
    case 'canonical':
      content = (
        <link key="linkCanonical" rel={contentItem.metaTagName} href={contentItem.metaTagValue} />
      );
      break;
    case 'title':
      content = <title key="title">{contentItem.metaTagValue}</title>;
      break;
    case 'description':
      content = (
        <meta name="description" content={contentItem.metaTagValue} key="metaDescription" />
      );

      break;
  }

  return <Head>{content}</Head>;
};

export default SEOMetaTag;
